import type { ITextInputData, IVisitFormInputField } from '@einfachgast/shared';


export function useFieldType (field: IVisitFormInputField<ITextInputData>): string {
  switch(field?.data?.format) {
    case 'phone': return 'tel';
    case 'email': return 'email';
    case 'date': return 'date';
    case 'number': return 'number';
    case 'text': return 'text';
    case 'url': return 'url';
    default: return 'text';
  }
}
import type { IVisitFormInputField, ITranslateableText } from '@einfachgast/shared';
import { useFieldType } from './use-field-type';
import { useI18n } from 'vue-i18n';

const isEmail = (input: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
const isUrl = (input: string) => /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(input);

// i know that this is in shared as well, but due to some weird vite shit i can only use types from there...
const fullNameRegex = "([a-zA-Z\u00E0\u00E1\u00E2\u00E4\u00E3\u00E5\u0105\u010D\u0107\u0119\u00E8\u00E9\u00EA\u00EB\u0117\u012F\u00EC\u00ED\u00EE\u00EF\u0142\u0144\u00F2\u00F3\u00F4\u00F6\u00F5\u00F8\u00F9\u00FA\u00FB\u00FC\u0173\u016B\u00FF\u00FD\u017C\u017A\u00F1\u00E7\u010D\u0161\u017E\u00C0\u00C1\u00C2\u00C4\u00C3\u00C5\u0104\u0106\u010C\u0116\u0118\u00C8\u00C9\u00CA\u00CB\u00CC\u00CD\u00CE\u00CF\u012E\u0141\u0143\u00D2\u00D3\u00D4\u00D6\u00D5\u00D8\u00D9\u00DA\u00DB\u00DC\u0172\u016A\u0178\u00DD\u017B\u0179\u00D1\u00DF\u00C7\u0152\u00C6\u010C\u0160\u017D\u2202\u00F0 ,.'-]+\\s).+";
const isName = (input: string) => new RegExp(fullNameRegex).test(input);

export function useExtractValidationRules (field: IVisitFormInputField, customValidators?: Array<(value: unknown) => Promise<string | boolean>>) {
  const { t } = useI18n();
  const fieldType = useFieldType(field);
  const validationRules: Array<(value: unknown) => Promise<string | boolean>> = [];

  if (customValidators && customValidators.length > 0) {
    validationRules.push(...customValidators);
  }

  if (field?.required) {
    if (field.type === 'multiselect') {
      validationRules.push((value) => Promise.resolve(((value as Array<ITranslateableText>)|| []).length > 0 || t('requiredField')));
    } else if (field.type === 'boolean') {
      validationRules.push((value) => Promise.resolve([false, true].includes(value as boolean) || t('requiredField')));
    } else {
      validationRules.push((value) => Promise.resolve(!!value || t('requiredField')));
    }
  }

  // Name Field
  if (field.fixed) {
    validationRules.push((value) => Promise.resolve(isName(value as string) || t('invalidNameFormat')));
  }

  if (fieldType === 'email') {
    validationRules.push((value) => Promise.resolve(!value || (isEmail(value as string) || t('invalidEmailMessage'))));
  }

  if (fieldType === 'number') {
    const isEmptyOrNumeric = (value?: string) => {
      // We do not validate empty fields. Required shit is validated in line 27
      if (!value || value === '') {
        return true;
      }
      return /^[0-9]*$/.test(`${(value)}`);
    };
    validationRules.push((value) => Promise.resolve(isEmptyOrNumeric(value as string) || t('pleaseEnterAValidNumber')));
  }

  if (fieldType === 'url') {
    validationRules.push((value) => Promise.resolve(!value || isUrl(value as string) || t('invalidUrlMessage')));
  }
  return validationRules;
}
import { computed, type WritableComputedRef } from 'vue';
import { useVisitStore } from '@/store/visit';
import type { IVisitFormInputField, VisitInputFieldValues } from '@einfachgast/shared';

export function useDynamicValueGetSet (field: IVisitFormInputField): WritableComputedRef<VisitInputFieldValues> {
  const visitStore = useVisitStore();
  const value = computed({
    get () {
      const entry = visitStore.inputFields.find(x => x.fieldId === field.id);
      return entry?.value as VisitInputFieldValues;
    },
    set (value) {
      const entry = visitStore.inputFields.find(x => x.fieldId === field.id);
      if (entry) {
        entry.value = value;
        return;
      }

      visitStore.inputFields.push(
        {
          fieldId: field.id,
          value,
        },
      );
    },
  });
  return value;
}